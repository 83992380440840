<template>
  <div>
    <div class="flex flex-wrap items-center py-2 mb-2 border-bottom">
      <div class="flex items-center mr-auto">
        <h3 class="uppercase text-xl lg:mb-0 mr-2">{{ $t('Tổng quan') }}</h3>
        <template v-if="selectedCircleLang">
          <i class="el-icon-arrow-right text-2xl mr-2" />
          <span
            v-if="selectedCircleLang.langData"
            class="flex-1 max-w-2xl 2xl:max-w-6xl truncate"
          >{{ selectedCircleLang.langData.name }}</span>
        </template>
      </div>
      <div class="flex items-center ml-2" v-if="selectedCircleLang">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="handleClose"
          class="cursor-pointer"
        >
          <path
            d="M6 18.4998L17.9998 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9998 18.4998L6 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="hodo-el">
      <template v-if="selectedCircleLang">
        <OverviewConferenceDetail />
      </template>
      <template v-else>
        <ConferenceHeader
          :placeholderSearch="$t('Tìm kiếm phiên hội thảo')"
          :textButton="$t('Thêm mới Phiên hội thảo')"
          btnIsDisabled
          @setSearchInput="setSearchInput"
          :inputSearch="inputSearch"
        />
        <div v-show="!isNoData && !inputSearch">
          <div v-for="(menu, index) in list_menu" :key="menu.id" class="p-3">
            <div
              class="d-flex align-items-center cursor-pointer mb-2"
              @click="menu.isShow = !menu.isShow"
            >
              <div>
                <p class="fs-16 fw-600 mb-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-show="menu.isShow"
                  >
                    <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-show="!menu.isShow"
                  >
                    <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
                  </svg>
                  {{ menu.title }} ({{
                  circles_data[index] && circles_data[index].page && (circles_data[index].page.total || 0)
                  }})
                </p>
              </div>
            </div>
            <div class="row" v-show="menu.isShow && !menu.loading && !loading">
              <template
                v-if="circles_data.length && circles_data[index].hasOwnProperty('data') && circles_data[index].data.length"
              >
                <div
                  class="col-md-4 col-2xl-3 mb-2"
                  v-for="circle in circles_data[index].data"
                  :key="circle.id + menu.title"
                  @click="goToConferenceCircle(circle)"
                >
                  <CircleListItem :circle="circle" :loading="menu.loading" />
                </div>
              </template>
              <div
                class="col-12 text-center"
                v-if="circles_data[index] && circles_data[index].data"
              >
                <template v-if="!loading">
                  <a
                    href="javascript:;"
                    class="txt-pri robo-16-400"
                    v-if="circles_data[index] && circles_data[index].page && (circles_data[index].page.page_num < circles_data[index].page.total_page)"
                    @click="(e) => handleGetMoreData(e, menu.id)"
                  >{{ $t("circle_feature.btn_load_more") }}</a>
                </template>
                <div class="txt-pri fs-16" v-else>{{ $t('schedule_page.lbl_loading') }}</div>
              </div>
            </div>
            <div class v-if="menu.loading">
              <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
            </div>
          </div>
        </div>
        <div v-show="inputSearch && !loading">
          <SearchSessionItem
            v-for="item in search_session.data"
            :key="item.id"
            :session_data="item"
            :valueSearch="inputSearch"
            @viewSessionDetail="handleViewSessionDetail"
          ></SearchSessionItem>
          <div class="col-12 text-center mt-3" v-if="search_session && search_session.data">
            <template v-if="!loading">
              <a
                v-if="search_session.page && (search_session.page.page_num < search_session.page.total_page)"
                href="javascript:;"
                class="txt-pri robo-16-400"
                @click="(e) => handleGetMoreData(e, 'search')"
              >{{ $t("circle_feature.btn_load_more") }}</a>
            </template>
          </div>
          <div v-if="!search_session.data.length">
            <p
              class="fs-20 mb-40 ml-3 mt-3"
              style="color: #84818A;"
            >{{ $t("conference.lbl_no_conference_now") }}</p>
          </div>
        </div>
        <div class v-if="loading">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </template>
    </div>

    <CustomModal
      :showModal="showSession"
      @hideModal="hideModal"
      :title="sessionData && getDataLang(sessionData).title || ''"
      titleClass="text-3xl"
    >
      <OverviewSessionConferenceDetail v-if="sessionData && sessionData.id" />
    </CustomModal>
  </div>
</template>

<script>
import ConferenceHeader from '@/components/Conference/Header.vue'
import CircleListItem from '@/pages/Conference/Circle/CircleListItem.vue'
import { IconSearch } from '@/components/Icon'
import OverviewConferenceDetail from '@/pages/Conference/OverviewDetail.vue'
import SearchSessionItem from '@/pages/Conference/Circle/SearchSessionItem.vue'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import CustomModal from '@/components/CustomModal'
import OverviewSessionConferenceDetail from '@/pages/Conference/OverviewSessionDetail.vue'

export default {
  name: 'OverviewConference',
  components: {
    ConferenceHeader,
    CircleListItem,
    IconSearch,
    OverviewConferenceDetail,
    SearchSessionItem,
    CustomModal,
    OverviewSessionConferenceDetail
  },
  data () {
    return {
      circles_data: [],
      loading: false,
      cvsts: [],
      scrollTop: 0,
      list_menu: [],
      cirle_session: {
        data: [],
        page: {
          page_num: 0,
          page_size: 0,
          total: 0,
          total_page: 0
        },
        meta: {
          isShow: true,
          loading: true
        }
      },
      search_session: {
        data: [],
        page: {
          page_num: 0,
          page_size: 0,
          total: 0,
          total_page: 0
        }
      },
      inputSearch: '',
      params: {
        keyword: '',
        sort_by: 'id',
        order: 'asc',
        page_num: 1,
        page_size: 500
      },
      selectedCircle: null,
      showSession: false,
      sessionData: {},
      LANGUAGE_CODES_FOR_DISEASE
    }
  },
  computed: {
    isNoData () {
      return !this.list_menu.length
    },
    selectedCircleLang () {
      if (!this.selectedCircle) return null

      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      const multipleLangData =
        this.selectedCircle?.circle_language ||
        this.selectedCircle?.circle_multilanguages

      const langData =
        multipleLangData?.find(
          item => (item?.Lang || item?.lang) === langValue
        ) || {}

      return {
        ...this.selectedCircle,
        langData
      }
    }
  },
  async created () {
    await this.checkingSearchQuery(this.$route.query?.s)
    await this.prepare()
  },
  watch: {
    '$route.query.id': {
      async handler (circleId) {
        await this.checkingHasCircleId(circleId)
      }
    },
    '$route.query.s': {
      handler (data) {
        if (data) {
          this.checkingSearchQuery(data)
        } else {
          this.closeSearchResult()
        }
      },
      deep: true
    }
  },
  methods: {
    async checkingHasCircleId (circleId) {
      if (circleId && !this.selectedCircle) {
        await this.showCircle()
      }
    },
    async prepare () {
      try {
        this.loading = true
        await this.checkingHasCircleId(this.$route.query?.id)
        await this.getConferenceGroups()
        for (let index = 0; index < this.list_menu.length; index++) {
          this.circles_data.push({
            data: [],
            page: {
              page_num: 0,
              page_size: 0,
              total: 0,
              total_page: 0
            }
          })
        }

        await Promise.all(
          this.list_menu.map(menu => this.getMyCircles(menu.id))
        )
        await this.getMySessionCircles()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getConferenceGroups () {
      if (!this.$route.params.conf_id) return

      let params = {
        conference_id: Number(this.$route.params.conf_id),
        page_num: 1,
        page_size: 500,
        order: 'asc',
        sort_by: 'id'
      }
      let self = this
      await this.$rf
        .getRequest('ConferenceRequest')
        .getAllConferenceGroups(params)
        .then(r => {
          self.list_menu = r.data.data.map(menu => {
            return {
              ...menu,
              isShow: true,
              loading: false
            }
          })
        })
    },
    async handleGetMoreData (e, status) {
      const self = this
      self.scrollTop += e.pageY
      const isNext = true
      if (status === 'search' && this.inputSearch) {
        await self.getSearchSessions(isNext).then(() => {
          document.getElementsByClassName('wrp')[0].scrollTop = self.scrollTop
        })
      } else {
        if (status) {
          await self.getMyCircles(status, isNext).then(() => {
            document.getElementsByClassName('wrp')[0].scrollTop =
              self.scrollTop
          })
        } else {
          await self.getMySessionCircles(isNext).then(() => {
            document.getElementsByClassName('wrp')[0].scrollTop =
              self.scrollTop
          })
        }
      }
    },
    async getMyCircles (conference_group_id, isNext = false) {
      if (!this.$route.params.conf_id) return

      let self = this

      const menuItemIndex = self.list_menu.findIndex(
        item => item.id === conference_group_id
      )
      menuItemIndex > -1 && (self.list_menu[menuItemIndex].loading = true)

      let params = {
        conference_id: Number(this.$route.params.conf_id),
        conference_group_id,
        page_num: isNext
          ? (menuItemIndex > -1 &&
              self.circles_data[menuItemIndex]?.page?.page_num + 1) ||
            1
          : 1,
        page_size: 500,
        order: 'asc',
        sort_by: 'conf_code'
      }
      self.loading = true

      await this.$rf
        .getRequest('ConferenceRequest')
        .getAllConferenceCircles(params)
        .then(r => {
          if (!isNext) {
            this.$set(self.circles_data, menuItemIndex, r.data)
          } else {
            if (r.data?.page.total) {
              let old =
                (menuItemIndex > -1 &&
                  self.circles_data[menuItemIndex]?.data) ||
                []
              let newObj = r.data?.data
              menuItemIndex > -1 &&
                (self.circles_data[menuItemIndex].data = old.concat(newObj))
              menuItemIndex > -1 &&
                (self.circles_data[menuItemIndex].page = r.data?.page)
            }
          }
        })
        .finally(() => {
          self.loading = false
          menuItemIndex > -1 && (self.list_menu[menuItemIndex].loading = false)
        })
    },
    async getMySessionCircles (isNext = false) {
      if (!this.$route.params.conf_id) return

      let self = this
      let params = {
        conference_id: Number(this.$route.params.conf_id),
        is_faculty: 1,
        page_num: isNext ? self.cirle_session?.page?.page_num + 1 : 1,
        page_size: 500,
        order: 'asc',
        sort_by: 'id'
      }
      await this.$rf
        .getRequest('ConferenceRequest')
        .getAllConferenceCircles(params)
        .then(r => {
          self.cirle_session = {
            ...r.data,
            meta: {
              isShow: true,
              loading: false
            }
          }
        })
    },
    goToConferenceCircle (data) {
      if (!data?.id) return

      this.selectedCircle = { ...data }
      this.$router
        .push({
          query: {
            ...this.$route.query,
            id: data?.id
          }
        })
        .catch(() => {})
    },
    async handleClose () {
      const { id, ...newQuery } = this.$route.query

      this.selectedCircle = null
      this.$router
        .push({
          query: newQuery
        })
        .catch(() => {})
      await this.prepare()
    },
    async setSearchInput (value) {
      this.inputSearch = value || ''
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            s: this.inputSearch
          }
        })
        .catch(() => {})
      await this.getSearchSessions()
    },
    async checkingSearchQuery (data) {
      if (!data) return
      this.inputSearch = data
      await this.getSearchSessions()
    },
    async getSearchSessions (isNext = false) {
      let self = this
      self.loading = true
      let params = {
        keyword: this.inputSearch,
        page_num: isNext ? self.search_session?.page?.page_num + 1 : 1,
        page_size: 10,
        order: 'asc',
        sort_by: 'title'
      }
      await this.$rf
        .getRequest('ConferenceRequest')
        .getSearchSessions(params)
        .then(r => {
          self.search_session = {
            ...r.data
          }
        })
        .finally(() => {
          self.loading = false
        })
    },
    async showCircle () {
      let self = this
      if (!this.$route.query?.id) return
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .showCircle(this.$route.query?.id)
        .then(r => {
          self.selectedCircle = { ...r.data }
          console.log(self.selectedCircle)
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          self.$router.push({ name: 'OrgConferenceList' }).catch(() => {})
          return false
        })
      return r?.data
    },
    closeSearchResult () {
      this.inputSearch = ''
      const { s, ...newQuery } = this.$route.query
      this.$router
        .replace({
          query: newQuery
        })
        .catch(() => {})
    },
    handleViewSessionDetail (item) {
      if (!item?.id) return

      this.$router
        .push({
          query: {
            ...this.$route.query,
            id: item.circle_id,
            sessionId: item.id
          }
        })
        .catch(() => {})

      this.showSession = true
      this.sessionData = { ...item }
    },
    hideModal () {
      this.showSession = false
      this.sessionData = null
      const { sessionId, s, id, ...newQuery } = this.$route.query

      if (this.inputSearch && s) {
        this.selectedCircle = null
        this.$router
          .replace({
            query: {
              ...newQuery,
              s: s
            }
          })
          .catch(() => {})
      } else {
        this.$router
          .replace({
            query: {
              ...newQuery,
              id: id
            }
          })
          .catch(() => {})
      }
    },
    getDataLang (item) {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      const langData =
        item?.pape_multilanguage?.find(paper => paper?.lang === langValue) ||
        {}
      return langData
    }
  }
}
</script>

<style scoped>
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.b-empty {
  width: 50%;
  max-width: 217px;
}
.empty-w {
  width: 100%;
  max-width: 455px;
}
.wrp {
  color: black;
  background-color: #f7f6f6;
  height: calc(100vh - 61px);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.item-w {
  padding: 1.3rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
}
.h-40px {
  height: 40px;
}
.h-196px {
  height: 196px;
}
.p-18 {
  padding: 12px 16px 12px 16px;
}
</style>
