<template>
  <div>
    <div class="hodo-el flex flex-wrap items-center py-2 mb-2 border-bottom">
      <h3 class="uppercase text-xl lg:mb-0 mr-2">{{ $t("Quản lý khách đăng ký") }}</h3>
      <div class="flex items-center ml-auto">
        <el-button type="primary" plain @click="onShowModalImportGuests">
          <span>{{ $t("Tải lên danh sách") }}</span>
          <i class="el-icon-upload el-icon-right ml-1"></i>
        </el-button>
        <el-button type="primary" plain disabled>
          <span>{{ $t("Xuất bản danh sách") }}</span>
          <i class="el-icon-download el-icon-right ml-1"></i>
        </el-button>
      </div>
    </div>
    <div class="hodo-el">
      <ConferenceHeader :placeholderSearch="$t('Tìm kiếm khách mời')" :textButton="$t('Thêm mới Khách mời')" :filterStatus="filterData" @setSearchInput="setSearchInput" @handleSelectFilter="handleSelectFilter" @handleEvent="handleCreateNewGuest" />
      <div v-loading="isLoading">
        <ConferenceGuestTable :tableData="tableData" :pagination="pagination" @handleSelectGuests="handleSelectGuests" @handleChangePage="handleChangePage" @refresh="getListGuests" />
      </div>
    </div>

    <ModalImportGuests ref="ModalImportGuests" @import="handleChangePage(1)"></ModalImportGuests>
    <ModalEditGuest id="ModalAddGuest" :isUpdate="false" @refresh="getListGuests" />
  </div>
</template>

<script>
import ConferenceGuestTable from '@/components/Conference/GuestTable.vue'
import ConferenceHeader from '@/components/Conference/Header.vue'
import ModalEditGuest from '@/components/Conference/Modal/ModalEditGuest.vue'
import ModalImportGuests from '@/components/Conference/Modal/ModalImportGuests'

export default {
  name: 'GuestsConference',
  components: {
    ConferenceHeader,
    ConferenceGuestTable,
    ModalEditGuest,
    ModalImportGuests
  },
  data () {
    const filterData = {
      options: [
        {
          value: 'all',
          label: 'Tất cả'
        },
        {
          value: 1,
          label: this.$t('multidisciplinary_board.lbl_status_not_approve')
        },
        {
          value: 2,
          label: this.$t('multidisciplinary_board.lbl_status_approved')
        },
        {
          value: 3,
          label: this.$t('circle_feature.lbl_decline')
        }
      ],
      value: 1
    }

    return {
      isLoading: false,
      inputSearch: '',
      filterData,
      tableData: [],
      pagination: null,
      selectedGuests: [],
      isShowAddGuest: false,
      params: {
        conference_id: Number(this.$route.params.conf_id),
        status: filterData.value,
        keyword: '',
        sort_by: 'id',
        order: 'desc',
        page_num: 1,
        page_size: 10,
        registration_type: 1
      },
      isShowModalAddGuest: false
    }
  },
  async created () {
    await this.getListGuests()
  },
  watch: {
    params: {
      async handler () {
        await this.getListGuests()
      },
      deep: true
    }
  },
  methods: {
    setSearchInput (value) {
      this.inputSearch = value || ''
      this.params.keyword = value
      this.params.page_num = 1
    },
    handleSelectFilter (value) {
      this.filterData.value = value || 'all'
      this.params.status = value === 'all' ? '' : value
      this.params.page_num = 1
    },
    handleCreateNewGuest () {
      this.showModalEditGuest(true)
    },
    handleSelectGuests (value) {
      this.selectedGuests = [...value]
    },
    async getListGuests (params) {
      try {
        const paramsData = {
          ...this.params,
          ...params
        }
        this.isLoading = true
        const res = await this.$rf.getRequest('ConferenceRequest').getAllConferenceRegistrants(paramsData)
        if (!res.data) return
        this.tableData = [...res.data?.data]
        this.pagination = { ...res.data?.page }
        this.isLoading = false
      } catch (err) {
        console.log(err)
        this.isLoading = false
      }
    },
    handleChangePage (value) {
      this.params.page_num = value
      // this.getListGuests()
    },
    showModalEditGuest (show) {
      this.isShowAddGuest = show
      window.$('#ModalAddGuest').modal(show ? 'show' : 'hide')
    },
    onShowModalImportGuests () {
      this.$refs.ModalImportGuests.openDialog()
    },
    handleAddGuest () {
      this.isShowModalAddGuest = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
</style>
