<template>
  <div>
    <div class="hodo-el flex flex-wrap items-center py-2 mb-2 border-bottom">
      <h3 class="uppercase text-xl lg:mb-0 mr-2">{{ $t('Danh sách báo cáo') }}</h3>
      <div class="flex items-center ml-auto">
        <el-button type="primary" plain @click="onShowModalImportReports">
          <span>{{ $t('Tải lên danh sách') }}</span>
          <i class="el-icon-upload el-icon-right ml-1"></i>
        </el-button>
        <el-button type="primary" plain disabled>
          <span>{{ $t('Xuất bản danh sách') }}</span>
          <i class="el-icon-download el-icon-right ml-1"></i>
        </el-button>
      </div>
    </div>
    <!-- <div style="height: 3000px; width: 100%;"></div> -->
    <div class="hodo-el">
      <ConferenceHeader
        :placeholderSearch="$t('Tìm kiếm báo cáo/diễn giả/từ khóa')"
        :textButton="$t('Thêm mới Báo cáo')"
        btnIsDisabled
        @setSearchInput="setSearchInput"
        @handleEvent="handleAddReport"
      />
      <ReportTable
        ref="ReportTable"
        @changePage="handleChangePage"
        @handleViewDetail="handleViewDetail"
      />
    </div>
    <ModalImportReports ref="ModalImportReports" @import="handleChangePage(1)"></ModalImportReports>

    <CustomModal
      :showModal="showReport"
      @hideModal="hideModal"
      :title="reportData && reportData.title || ''"
      titleClass="text-3xl"
    >
      <ReportDetail :isUpdate="isUpdateReport" />
    </CustomModal>
  </div>
</template>

<script>
import ReportTable from '@/components/Conference/ReportTable.vue'
import ConferenceHeader from '@/components/Conference/Header.vue'
import ModalImportReports from '@/components/Conference/Modal/ModalImportReports'
import CustomModal from '@/components/CustomModal'
import ReportDetail from '@/pages/Conference/ReportDetail.vue'

export default {
  name: 'ReportsConference',
  components: {
    ConferenceHeader,
    ReportTable,
    ModalImportReports,
    CustomModal,
    ReportDetail
  },
  data () {
    return {
      params: {
        keyword: '',
        page_num: 1
      },
      showReport: false,
      reportData: null,
      isUpdateReport: false
    }
  },
  async created () {
    if (this.$route.query.reportId) {
      this.showReport = true
    } else {
      this.showReport = false
    }
  },
  methods: {
    onShowModalImportReports () {
      this.$refs.ModalImportReports.openDialog()
    },
    setSearchInput (value) {
      this.params = {
        keyword: value || '',
        page_num: 1
      }
      this.$refs.ReportTable.getListGuests(this.params)
    },
    handleChangePage (value) {
      this.params.page_num = value
      this.$refs.ReportTable.getListGuests(this.params)
    },
    handleViewDetail (item) {
      if (!item?.id) return

      this.$router
        .push({
          query: {
            ...this.$route.query,
            reportId: item.id
          }
        })
        .catch(() => {})

      this.showReport = true
      this.isUpdateReport = true
      this.reportData = { ...item }
    },
    hideModal () {
      this.showReport = false
      this.reportData = null
      const { reportId, ...newQuery } = this.$route.query
      this.$router
        .replace({
          query: newQuery
        })
        .catch(() => {})
    },
    handleAddReport () {
      // this.isUpdateReport = false
      // this.showReport = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
</style>
