<template>
  <div class="bg-white h-full">
    <div class="h-full" v-show="!mounting">
      <div class="w-full h-full">
        <ConferenceTreeView />
      </div>
    </div>
    <div v-if="mounting" class="d-flex align-items-center justify-content-center py-5">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import ConferenceTreeView from '@/pages/Conference/TreeView/index.vue'
import _ from 'lodash'

export default {
  name: 'OrganizationConferenceDetail',
  components: {
    ConferenceTreeView
  },
  data () {
    return {
      mounting: false,
      element: null
    }
  },
  created () {
    if (
      _.isEmpty(this.$featureList) &&
      _.isEmpty(this.$featureWsList) &&
      _.isEmpty(this.$featureOrgList)
    ) {
      this.mounting = true
      setTimeout(() => {
        this.mounting = false
      }, 5000)
    } else {
      this.mounting = false
    }
  },
  mounted () {
    this.element = document.getElementById('page-content-wrapper')

    this.element.style.height = '100vh'
    this.element.style.minHeight = 'auto'
  },
  beforeDestroy () {
    this.element = document.getElementById('page-content-wrapper')
    this.element.removeAttribute('style')
  },
  watch: {
    $featureList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureWsList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureOrgList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .overlay-content {
    min-height: auto !important;
    height: 100vh;
  }
}
</style>
