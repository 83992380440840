<template>
  <div>
    <div class="hodo-el flex flex-wrap items-center py-2 mb-2 border-bottom">
      <h3 class="uppercase text-xl lg:mb-0 mr-2">{{ $t('Quản lý Báo cáo viên') }}</h3>
      <div class="flex items-center ml-auto">
        <el-button
          type="primary"
          plain
          class="outline-focus-none"
          @click="onShowModalImportSpeakers"
        >
          <span>{{ $t('Tải lên danh sách') }}</span>
          <i class="el-icon-upload el-icon-right ml-1"></i>
        </el-button>
        <el-button type="primary" plain disabled>
          <span>{{ $t('Xuất bản danh sách') }}</span>
          <i class="el-icon-download el-icon-right ml-1"></i>
        </el-button>
      </div>
    </div>
    <div class="hodo-el">
      <ConferenceHeader
        :placeholderSearch="$t('Tìm kiếm báo cáo viên')"
        :textButton="$t('Thêm mới Báo cáo viên')"
        @setSearchInput="setSearchInput"
        @handleEvent="handleAddSpeaker"
      />
      <div v-loading="isLoading">
        <ConferenceSpeakerTable
          :tableData="tableDataLang"
          :pagination="pagination"
          @handleChangePage="handleChangePage"
          @refresh="getListSpeakers"
        />
      </div>
    </div>
    <ModalImportSpeakers ref="ModalImportSpeakers" @import="handleChangePage(1)"></ModalImportSpeakers>
    <ModalEditSpeaker
      :isUpdate="false"
      :isShow="isShowModalAddSpeaker"
      @onClose="()=>{isShowModalAddSpeaker=false}"
      @refresh="getListSpeakers"
    />
  </div>
</template>

<script>
import ConferenceHeader from '@/components/Conference/Header.vue'
import ConferenceSpeakerTable from '@/components/Conference/SpeakersTable.vue'
import ModalImportSpeakers from '@/components/Conference/Modal/ModalImportSpeakers'
import { LANGUAGE_CODES_FOR_DISEASE } from '../../lang'
import ModalEditSpeaker from '../../components/Conference/Modal/ModalEditSpeaker.vue'

export default {
  name: 'SpeakersConference',
  components: {
    ConferenceHeader,
    ConferenceSpeakerTable,
    ModalImportSpeakers,
    ModalEditSpeaker
  },
  data () {
    return {
      isLoading: false,
      inputSearch: '',
      tableData: [],
      pagination: null,
      selectedGuests: [],
      params: {
        keyword: '',
        sort_by: 'id',
        order: 'desc',
        page_num: 1,
        page_size: 10
      },
      isShowModalAddSpeaker: false
    }
  },
  async created () {
    await this.getListSpeakers()
  },
  computed: {
    tableDataLang () {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      return this.tableData?.map(item => {
        const langData = item?.user_infor?.speaker_multi_language?.find(
          speakerInfo => speakerInfo?.lang === langValue
        )
        return {
          ...item,
          langData
        }
      })
    }
  },
  methods: {
    onShowModalImportSpeakers () {
      this.$refs.ModalImportSpeakers.openDialog()
    },
    setSearchInput (value) {
      this.inputSearch = value || ''
      this.params.keyword = value
      this.params.page_num = 1
      this.getListSpeakers()
    },
    handleCreateNewGuest () {
      console.log('Create new guest success')
    },
    async getListSpeakers (params) {
      const conference_id = Number(this.$route.params.conf_id)

      if (!conference_id) return

      try {
        const paramsData = {
          ...this.params,
          ...params,
          conference_id,
          registration_type: 2,
          status: 2
        }
        this.isLoading = true
        const res = await this.$rf
          .getRequest('ConferenceRequest')
          .getAllConferenceRegistrants(paramsData)
        if (!res.data) return
        this.tableData = [...res.data?.data]
        this.pagination = { ...res.data?.page }
        this.isLoading = false
      } catch (err) {
        console.log(err)
        this.isLoading = false
      }
    },
    handleChangePage (value) {
      this.params.page_num = value
      this.getListSpeakers()
    },
    handleAddSpeaker () {
      this.isShowModalAddSpeaker = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
.outline-focus-none:focus {
  outline: none;
}
</style>
