<template>
  <div class="container">
    <div v-if="!loading">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <p class="fs-24 fw-600 flex-1">{{ circleLang && Object.keys(circleLang.langData).length ?
              circleLang.langData.name :
              circleLang.name }}</p>
            <!-- <svg
              @click="showModalEditSession(true)"
              class="cursor-pointer"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                fill="#20409B"
              />
            </svg>-->
          </div>
          <div class="d-flex mb-3">
            <div class="icinfo">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14 17H4V19H14V17ZM20 9H4V11H20V9ZM4 15H20V13H4V15ZM4 5V7H20V5H4Z" fill="#84818A" />
              </svg>
            </div>
            <p class="white-s-pre">{{ circleLang && Object.keys(circleLang.langData).length ?
              circleLang.langData.location :
              circleLang.location }}</p>
          </div>
          <div class v-if="isPublicVideo">
            <div class="flex items-center w-full mb-2">
              <div class="mr-2">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path
                    d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
                  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
                </svg>
              </div>
              <div class="fs-14 flex flex-1 items-center">
                <span class="fw-600 mr-2">Video</span>
                <div class="ml-auto">
                  <button class="flex items-end btn btn--bg-primary text-white"
                    :class="isPublicShowVideo && !isSubmittingPublicShowVideo ? 'bg-danger' : 'bg-pri'"
                    @click.stop="toggleIsPublicShowVideo" :disabled="isSubmittingPublicShowVideo">
                    <template v-if="isPublicShowVideo && !isSubmittingPublicShowVideo">
                      <i class="el-icon-circle-close fs-18 mr-2" />
                      <span>{{ $t('Ẩn Video') }}</span>
                    </template>
                    <template v-else-if="!isPublicShowVideo && !isSubmittingPublicShowVideo">
                      <i class="el-icon-circle-check fs-18 mr-2" />
                      <span>{{ $t('Hiển thị Video cho người xem') }}</span>
                    </template>
                    <template v-else>
                      <i class="el-icon-loading fs-18 mr-2" />
                      <span>{{ $t('Đang xử lý') }}</span>
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="mb-3" :class="{ 'max-w-5xl': !zoomVideo }">
              <video-player class="video-player-box" ref="videoPlayer" :options="playerOptions"
                @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)"
                @loadeddata="onPlayerLoadeddata($event)" @waiting="onPlayerWaiting($event)"
                @playing="onPlayerPlaying($event)" @timeupdate="onPlayerTimeupdate($event)"
                @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
                @statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>
              <div class="bg-gray-50 flex items-center">
                <div class="bg-gray-100 hover:bg-gray-200 ml-auto cursor-pointer p-2 border transition-all duration-200"
                  @click.stop="toggleZoomVideo">
                  <span class="inline-block w-6 h-6 mr-2">
                    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_1_2" fill="white">
                        <path d="M0 41C0 38.7909 1.79086 37 4 37H38V77H4C1.79086 77 0 75.2091 0 73V41Z" />
                      </mask>
                      <path d="M0 41C0 38.7909 1.79086 37 4 37H38V77H4C1.79086 77 0 75.2091 0 73V41Z"
                        stroke="currentColor" stroke-width="16" mask="url(#path-1-inside-1_1_2)" />
                      <mask id="path-2-inside-2_1_2" fill="white">
                        <path
                          d="M30 26C30 23.7909 31.7909 22 34 22H96C98.2091 22 100 23.7909 100 26V73C100 75.2091 98.2091 77 96 77H30V26Z" />
                      </mask>
                      <path
                        d="M30 26C30 23.7909 31.7909 22 34 22H96C98.2091 22 100 23.7909 100 26V73C100 75.2091 98.2091 77 96 77H30V26Z"
                        stroke="currentColor" stroke-width="16" mask="url(#path-2-inside-2_1_2)" />
                    </svg>
                  </span>
                  <span v-if="!zoomVideo">{{ $t('Phóng lớn') }}</span>
                  <span v-else>{{ $t('Thu nhỏ') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="icinfo">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5947 18.2565V13.6353M12.5947 12.6891C12.8067 10.038 11.3857 5.02198 4.00492 6.16728C3.89887 8.34122 5.46835 12.6891 12.5947 12.6891ZM12.5947 12.6891C13.7081 11.5226 15.723 8.56014 20.5 10C20.0654 12.0378 18.3162 14.7488 12.5947 13.6353M12.5947 12.6891V13.6353"
                  stroke="#84818A" stroke-width="2" />
                <rect x="3" y="18" width="17" height="2" fill="#84818A" />
              </svg>
            </div>
            <div class="row">
              <p class="fs-14 fw-600 mb-1 mt-1">{{ $t('conference.lbl_chair') }}</p>
              <div class="flex flex-col lg:flex-row flex-wrap -mx-2">
                <!-- <div v-for="seed in membersData.data" :key="seed.id">
                  <div
                    :set="seedInfo = seed.user_info"
                    class="d-flex align-items-center"
                    v-if="seed.id"
                  >
                    <div
                      @click="goToProfile(seed.user_info)"
                      class="cursor-pointer"
                      style="min-width: 40px"
                    >
                      <span
                        v-if="seedInfo.avatar"
                        class="avatar avatar-sm avatar-rounded"
                        :style="
                        'background-image: url(' +
                        getImageURL(seedInfo.avatar) +
                        ')'
                      "
                      ></span>
                      <span
                        v-if="!seedInfo||!seedInfo.avatar"
                        class="avatar avatar-sm avatar-rounded bg-pri text-white"
                      >
                        {{
                        seedInfo.name.charAt(0) ||
                        seedInfo.email.charAt(0)
                        }}
                      </span>
                    </div>
                    <div class="flex-1">
                      <p class="fs-14 fw-600 txt-black mb-1">{{ seedInfo.name }}</p>
                      <p
                        class="fs-12 fw-500 txt-grey-600 mb-0"
                      >{{ seedInfo.doctor_infor && seedInfo.doctor_infor.chuc_vu }}</p>
                    </div>
                  </div>
                </div>-->
                <template v-for="seed in membersData.data">
                  <div class="lg:w-1/3 px-2 mb-2" :key="seed.id" v-if="seed.user_info && seed.user_info.doctor_infor">
                    <div :set="seedInfo = seed.user_info" class="d-flex align-items-center cursor-pointer"
                      @click="goToProfile(seed.user_info)">
                      <div style="min-width: 40px">
                        <span v-if="seedInfo.avatar" class="avatar avatar-sm avatar-rounded" :style="'background-image: url(' +
                          getImageURL(seedInfo.avatar) +
                          ')'
                          "></span>
                        <span v-if="!seedInfo || !seedInfo.avatar"
                          class="avatar avatar-sm avatar-rounded bg-pri text-white">
                          {{
                            seedInfo.name.charAt(0) ||
                            seedInfo.email.charAt(0)
                          }}
                        </span>
                      </div>
                      <div class="flex-1">
                        <p class="fs-14 fw-600 txt-black mb-1">{{ getMultilangValue(seedInfo.multilang) ?
                          getMultilangValue(seedInfo.multilang).name : seedInfo.name }}</p>
                        <p class="fs-12 fw-500 txt-grey-600 mb-0">{{ getMultilangValue(seedInfo.multilang) ?
                          getMultilangValue(seedInfo.multilang).job_title : (seedInfo.doctor_infor &&
                            seedInfo.doctor_infor.chuc_vu) }}</p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <CircleSession v-if="circle" class="col-12" :isAdmin="isAdmin" :seedMember="circleLang && circleLang.seed"
          @handleViewSessionDetail="handleViewSessionDetail" />

        <CircleMembers />
      </div>
    </div>

    <div class="loading-wr" v-if="loading">
      <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
    </div>

    <!-- <CustomModal
      :showModal="showSession"
      @hideModal="hideModal"
      :title="sessionData && getDataLang(sessionData).title || ''"
      titleClass="text-3xl"
    >
      <OverviewSessionConferenceDetail v-if="sessionData && sessionData.id" />
    </CustomModal>-->
  </div>
</template>

<script>
import 'videojs-resolution-switcher'
import 'videojs-resolution-switcher/lib/videojs-resolution-switcher.css'
import 'videojs-watermark'

import ConferenceImg from '@/assets/images/conference_room.png'
import liveStream from '@/assets/images/livestream.png'
import CircleAddMember from '@/components/Circles/CircleAddMember'
import CircleAssignRole from '@/components/Circles/CircleAssignRole'
import CustomModal from '@/components/CustomModal'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import CircleMembers from '@/pages/Conference/Circle/CircleMembers.vue'
import CircleSession from '@/pages/Conference/Circle/CircleSession'
import OverviewSessionConferenceDetail from '@/pages/Conference/OverviewSessionDetail.vue'
import appUtils from '@/utils/appUtils'
import { CIRCLE_TYPE, CONFERENCE_MEMBERS_TYPE } from '@/utils/constants'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'OverviewConferenceDetail',
  components: {
    CircleAddMember,
    CircleAssignRole,
    CircleSession,
    CircleMembers,
    CustomModal,
    OverviewSessionConferenceDetail,
    videoPlayer
  },
  data () {
    const conferenceRoomStatus = true
    const playerSources = [
      // {
      //   src: 'https://vjs.zencdn.net/v/oceans.mp4?SD',
      //   type: 'video/mp4',
      //   label: 'SD',
      //   res: 360
      // },
      // {
      //   src: 'https://vjs.zencdn.net/v/oceans.mp4?HD',
      //   type: 'video/mp4',
      //   label: 'HD',
      //   res: 720
      // }
      // {
      //   type: 'video/mp4',
      //   src: 'http://7xkwa7.media1.z0.glb.clouddn.com/sample_video_H',
      //   label: '1080P',
      //   res: 1
      // },
      // {
      //   type: 'video/mp4',
      //   src:
      //     'http://221.11.100.42/7xkwa7.media1.z0.glb.clouddn.com/sample_video_M?wsiphost=local',
      //   label: '720P',
      //   res: 2
      // },
      // {
      //   type: 'video/mp4',
      //   src: 'http://7xkwa7.media1.z0.glb.clouddn.com/sample_video_L',
      //   label: '360P',
      //   res: 3
      // }
    ]

    return {
      circle: null,
      loading: false,
      circle_member: null,
      accepted_members: [],
      pending_members: [],
      banned_members: [],
      invited_members: [],
      type: 2,
      member_loading: false,
      user_id: null,
      unread_message_count: 0,
      activeConversation: null,
      updating_info: false,
      form_edit: {
        name: '',
        description: '',
        logo: null,
        background: null
      },
      liveStream,
      CIRCLE_TYPE,
      user_100ms_role: conferenceRoomStatus ? 'guest' : 'viewer',
      ConferenceImg,
      conferenceRoomStatus,
      ctaRefreshRoles: 0,
      selectedFeature: 'conference',
      membersData: {},
      sessionData: null,
      showSession: false,
      zoomVideo: false,
      playerSources,
      playerOptions: {
        muted: false,
        language: this.$lang,
        plugins: {
          videoJsResolutionSwitcher: {
            ui: true,
            default: 3,
            dynamicLabel: true
          }
          // watermark: {
          //   image: 'https://hodo.app/hodologo_256x256.png'
          //   // url: 'https://hodo.com.vn'
          // }
        },
        playbackRate: 1,
        playbackRates: [0.7, 1.0, 1.5, 2.0]
        // sources: playerSources,
        // poster: '/static/images/author-3.jpg'
      },
      isPublicShowVideo: false,
      isSubmittingPublicShowVideo: false
    }
  },
  computed: {
    isMember () {
      return !!this.circle_member && this.circle_member.status === 2
    },
    isAdmin () {
      return (
        (!!this.isMember && this.circle_member.role === 1) ||
        this.circle?.created_by === this.user_id
      )
    },
    isMyHasAssignRole () {
      return ![null, '', 'viewer', 'guest', 'hls-viewer'].includes(
        this.circle_member?.one_hundred_ms_role
      )
    },
    circleLang () {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      const multipleLangData = this.circle?.circle_multilanguages || []

      const langData =
        multipleLangData?.find(
          item => (item?.Lang || item?.lang) === langValue
        ) || {}

      return { ...this.circle, langData }
    },
    player () {
      return this.$refs.videoPlayer.player
    },
    isPublicVideo () {
      return (
        this.circle?.live_room_recording_url &&
        appUtils.isJSON(this.circle?.live_room_recording_url)
      )
    }
    // isPublicShowVideo: {
    //   get () {
    //     return this.circle?.is_show_url_record === 2
    //   },
    //   set (newValue) {
    //     if (newValue !== this.circle?.is_show_url_record) {
    //       return newValue
    //     }
    //     return this.circle?.is_show_url_record
    //   }
    // }
  },

  async mounted () {
    this.user_id = appUtils.getLocalUser().id
    await this.prepare()
    this.get100msRole()
    this.getConferenceCircleMembers()
  },
  methods: {
    handleClickConference () {
      this.ctaRefreshRoles++
      this.selectedFeature = 'conference'
      // if (this.isAdmin || this.isMyHasAssignRole) {
      if (this.isAdmin) {
        this.showModal(true, '#modalAssignRole')
      } else {
        this.goToMultiLangConference()
      }
    },
    goToProfile (data) {
      const doctorId = data?.doctor_infor?.id
      if (!doctorId) return
      this.$router
        .push({ name: 'DoctorProfile', params: { id: doctorId } })
        .catch(() => { })
    },
    goToList () {
      this.$emit('handleClose')
      // this.$router.push({ name: 'OrganizationConference' }).catch(() => {})
    },
    members () {
      let type = this.type
      if (type === 0) {
        return this.pending_members
      }
      if (type === 1) {
        return this.invited_members
      }
      if (type === 2) {
        return this.accepted_members
      }
      if (type === 3) {
        return this.banned_members
      }
    },
    async prepare () {
      try {
        this.loading = true
        let c = await this.showCircle()
        let m = await this.getMember()
        this.get100msRole()
        this.getCircleMembers(2)
        // if (
        //   ((m && m.status === 2) || c?.created_by === this.user_id) &&
        //   this.$conversationsClient &&
        //   c?.twilio_conversation?.twilio_conv_id
        // ) {
        //   this.createConversation(c?.twilio_conversation?.twilio_conv_id)
        // }
        if (
          (m && m.status !== 3 && m.role === 1) ||
          c?.created_by === appUtils.getLocalUser().id
        ) {
          this.getCircleMembers(0)
          this.getCircleMembers(1)
          this.getCircleMembers(3)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    async showCircle () {
      let self = this
      if (!this.$route.query?.id) return
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .showCircle(this.$route.query?.id)
        .then(r => {
          const circleType = r.data?.type
          if (circleType === CIRCLE_TYPE.LIVE) {
            self.$router.replace({
              path: '/live-circle/' + self.$route.query?.id
            })
            return
          }
          self.circle = { ...r.data }
          // self.circle.type = 2
          self.form_edit = {
            name: r.data?.name,
            description: r.data?.description,
            logo: r.data?.logo,
            background: r.data?.background
          }
          self.isPublicShowVideo = r.data?.is_show_url_record === 2
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          self.$router.push({ name: 'OrgConferenceList' }).catch(() => { })
          return false
        })
      return r?.data
    },
    async getCircleMembers (status, isNext) {
      let self = this
      if (!this.$route.query?.id) return
      let page = 1
      if (!isNext) {
        if (status === 2) {
          self.accepted_members = []
        }
        if (status === 0) {
          self.pending_members = []
        }
        if (status === 1) {
          self.invited_members = []
        }
        if (status === 3) {
          self.banned_members = []
        }
      } else {
        if (status === 2) {
          page = self.accepted_members.current_page + 1
        }
        if (status === 0) {
          page = self.pending_members.current_page + 1
        }
        if (status === 1) {
          page = self.invited_members.current_page + 1
        }
        if (status === 3) {
          page = self.banned_members.current_page + 1
        }
      }
      let params = {
        page: page,
        limit: 10
      }
      if (!isNaN(status)) {
        params.status = status
      }
      self.member_loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getCircleMembers(this.$route.query?.id, params)
        .then(r => {
          if (status === 2) {
            self.accepted_members.current_page = r.data.current_page
            if (!isNext) {
              self.accepted_members = r.data
            } else {
              if (r.data?.count) {
                let old = self.accepted_members.data
                let newObj = r.data.data
                self.accepted_members.data = old.concat(newObj)
              }
            }
          }
          if (status === 0) {
            self.pending_members.current_page = r.data.current_page
            if (!isNext) {
              self.pending_members = r.data
            } else {
              if (r.data?.count) {
                let old = self.pending_members.data
                let newObj = r.data.data
                self.pending_members.data = old.concat(newObj)
              }
            }
          }
          if (status === 1) {
            self.invited_members.current_page = r.data.current_page
            if (!isNext) {
              self.invited_members = r.data
            } else {
              if (r.data?.count) {
                let old = self.invited_members.data
                let newObj = r.data.data
                self.invited_members.data = old.concat(newObj)
              }
            }
          }
          if (status === 3) {
            self.banned_members.current_page = r.data.current_page
            if (!isNext) {
              self.banned_members = r.data
            } else {
              if (r.data?.count) {
                let old = self.banned_members.data
                let newObj = r.data.data
                self.banned_members.data = old.concat(newObj)
              }
            }
          }
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
        })
        .finally(() => {
          self.member_loading = false
        })
    },
    async assignMember (circle_member_id, role_name) {
      try {
        const roleRaw =
          typeof role_name === 'string' && appUtils.isJSON(role_name)
            ? JSON.parse(role_name)
            : {
              [this.selectedFeature]: role_name
            }
        const member_role_ms =
          typeof this.circle_member?.one_hundred_ms_role === 'string' &&
            appUtils.isJSON(this.circle_member?.one_hundred_ms_role)
            ? JSON.parse(this.circle_member?.one_hundred_ms_role)
            : {}
        const role = {
          ...member_role_ms,
          ...roleRaw
        }
        const params = {
          circle_member_id: circle_member_id,
          role: JSON.stringify(role)
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .assignConferenceRole(params)
        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    async getMember () {
      const self = this
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getMemberByUserId(
          this.$route.query?.id,
          appUtils.getLocalUser().id,
          null,
          false
        )
        .then(async r => {
          if (
            self.circle?.created_by === self.$user?.id &&
            !r.data?.one_hundred_ms_role
          ) {
            const role = {
              conference: 'presenter',
              livestream: 'broadcaster'
            }
            const res = await self.assignMember(
              r.data?.id,
              JSON.stringify(role)
            )
            self.circle_member = {
              ...r.data,
              one_hundred_ms_role:
                typeof res.data.one_hundred_ms_role === 'string' &&
                  appUtils.isJSON(res.data.one_hundred_ms_role)
                  ? JSON.parse(res.data.one_hundred_ms_role || '{}')
                  : res.data.one_hundred_ms_role
            }
            return r
          }

          const role =
            typeof r.data?.one_hundred_ms_role === 'string' &&
              appUtils.isJSON(r.data?.one_hundred_ms_role)
              ? JSON.parse(r.data?.one_hundred_ms_role)
              : {
                conference: 'guest',
                livestream: 'viewer'
              }
          if (
            typeof r.data?.one_hundred_ms_role === 'string' &&
            !appUtils.isJSON(r.data?.one_hundred_ms_role)
          ) {
            await self.assignMember(r.data?.id, JSON.stringify(role))
          }

          self.circle_member = {
            ...r.data
          }
          return r
        })
        .catch(() => {
          let r = {
            data: null
          }
          return r
        })
      return r?.data
    },
    async getConferenceCircleMembers () {
      if (!this.$route.query?.id) return

      try {
        const circle_id = this.$route.query?.id
        const params = {
          role: CONFERENCE_MEMBERS_TYPE.chair,
          page_num: 1,
          page_size: 1000
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .getConferenceCircleMembers(circle_id, params)

        const responseData = response.data || {}
        this.membersData = responseData
      } catch (error) {
        console.log(error)
      }
    },
    async get100msRole () {
      await this.$rf
        .getRequest('DoctorRequest')
        .checkMyRoleConferenceRoom({
          circle_id: this.circle.id,
          user_id: this.user_id
        })
        .then(res => {
          this.user_100ms_role =
            res.data?.one_hundred_ms_role ||
            JSON.stringify({
              conference: 'guest',
              livestream: 'viewer'
            })
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    },
    changeMemType (t) {
      if (t === 0 || t === 1 || t === 2 || t === 3) {
        this.type = t
        this.getCircleMembers(t)
      }
    },
    showModal (show, modalRef) {
      window.$(modalRef).modal(show ? 'show' : 'hide')
    },
    refresh () {
      this.getCircleMembers(0)
      this.getCircleMembers(1)
      this.getCircleMembers(2)
      this.getCircleMembers(3)
      this.showModal(false, '#modalAddCMem')
    },
    goToAttachment () {
      this.$router
        .push({
          name: 'WorkspaceConferenceDocument',
          params: { id: this.$route.query?.id }
        })
        .catch(() => { })
    },
    async goToMultiLangConference () {
      if (!this.circle.one_hundred_ms_conf_id) return

      const { id, one_hundred_ms_conf_id } = this.circle
      await this.get100msRole().then(() => {
        let token = appUtils.getLocalToken()
        // let dm = 'http://localhost:3000'
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.live.hodo.app'
        } else {
          dm = 'https://live.hodo.app'
        }

        const roleObj = appUtils.isJSON(this.user_100ms_role)
          ? JSON.parse(this.user_100ms_role)
          : {
            [this.selectedFeature]: this.user_100ms_role
          }
        const role = roleObj[this.selectedFeature]
        const routeRaw = `${dm}/redirect.html?token=${token}&room_id=${one_hundred_ms_conf_id}&circle_id=${id}&type=conference&role=${role ||
          'guest'}`
        console.log(routeRaw)
        window.open(routeRaw, '_blank')
      })
    },
    handleClickToRoom () {
      this.ctaRefreshRoles++
      this.selectedFeature = 'livestream'

      // if (this.isAdmin || this.isMyHasAssignRole) {
      if (this.isAdmin) {
        this.showModal(true, '#modalAssignRole')
      } else {
        this.goToStream()
      }
    },
    async uploadFiles (file, circle) {
      const params = {
        file,
        FolderTarget: `circle`,
        IdTarget: circle.id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    onShowPickFile () {
      window.$('input[id="circle-file-upload"]').trigger('click')
    },
    async onSelectFile (event, targetKey) {
      const res = await this.uploadFiles(event.target.files[0], this.circle)
      this.form_edit[targetKey] = res
    },
    handleViewSessionDetail (item) {
      if (!item?.id) return

      this.$router
        .push({
          query: {
            ...this.$route.query,
            sessionId: item.id
          }
        })
        .catch(() => { })

      this.showSession = true
      this.sessionData = { ...item }
    },
    hideModal () {
      this.showSession = false
      this.sessionData = null
      const { sessionId, ...newQuery } = this.$route.query
      this.$router
        .replace({
          query: newQuery
        })
        .catch(() => { })
    },
    getDataLang (item) {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
      const langData =
        item?.pape_multilanguage?.find(paper => paper?.lang === langValue) ||
        {}
      return langData
    },
    showModalEditSession (show) {
      window.$(`#ModalEditSession`).modal(show ? 'show' : 'hide')
    },
    getMultilangValue (data) {
      if (!data) return ''
      return data.find(item => (item.Lang || item.lang) === this.lang)
    },
    onPlayerPlay (player) {
      // console.log('player play!', player)
    },
    onPlayerPause (player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded (player) { },
    onPlayerTimeupdate (player) { },
    onPlayerLoadeddata (player) { },
    onPlayerWaiting (player) { },
    onPlayerPlaying (player) { },
    onPlayerCanplay (player) { },
    onPlayerCanplaythrough (player) { },
    playerStateChanged (playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    playerReadied (player) {
      if (player.watermark && this.circle?.live_room_recording_url) {
        player.watermark({
          image: this.circle?.logo || 'https://hodo.app/hodologo_256x256.png'
        })
      }
      if (player.updateSrc && this.circle?.live_room_recording_url) {
        const sourcesData = appUtils.isJSON(
          this.circle?.live_room_recording_url
        )
          ? JSON.parse(this.circle?.live_room_recording_url)
          : []
        const sources = sourcesData.data
        this.playerSources = [...sources]
        player.updateSrc(this.playerSources)
        player.on('resolutionchange', function () {
          console.info('Source changed to %s', player.src())
        })
      }
    },
    toggleZoomVideo () {
      this.zoomVideo = !this.zoomVideo
    },
    async toggleIsPublicShowVideo () {
      if (!this.$route.params.conf_id) return

      try {
        // is_show_url_record
        this.isSubmittingPublicShowVideo = true
        this.isPublicShowVideo = !this.isPublicShowVideo
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .updateIsPublicVideoConference({
            id: this.circle?.id,
            is_show_url_record: this.isPublicShowVideo ? 2 : 1,
            conference_id: Number(this.$route.params.conf_id)
          })
        if (!res?.data) {
          this.isPublicShowVideo = !this.isPublicShowVideo
          return
        }
        this.isPublicShowVideo = res?.data?.is_show_url_record === 2
      } catch (error) {
        console.log(error.response?.data)
        this.$message({
          type: 'error',
          message: this.$t(
            `conference.message_errors.${error.response?.data?.message}`
          )
        })
        this.isPublicShowVideo = !this.isPublicShowVideo
      } finally {
        this.isSubmittingPublicShowVideo = false
      }
    }
  },
  destroyed () {
    if (this.activeConversation) {
      this.activeConversation.removeListener(
        'messageAdded',
        this.getUnreadChat
      )
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .custom:hover {
    background-color: #e4e7ec;
  }

  .px {
    padding: 9px 43px;
  }

  .ml-40 {
    margin-left: 40px;
  }

  .container {
    width: 1024px;
  }

  .p-48 {
    padding: 0px 48px 0px 48px;
  }

  .white-s-pre {
    white-space: pre-wrap;
  }

  .icinfo {
    margin-right: 16px;
  }

  .bd-blur-gray {
    border-color: #98a2b3;
  }

  .nav-item.active a {
    color: #20419b !important;
    font-weight: 500;
  }

  .modal-dialog.modal-qr {
    position: relative;
    display: table;
    /* This is important */
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    min-width: 300px;
  }

  .btn-FEF3F2 {
    background-color: #fef3f2;
    color: #b42318;
    border-color: #fef3f2;
  }

  .btn-add-member {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed #20419b;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary */

    color: #20419b;
    padding: 8px;
    cursor: pointer;
  }

  .open-chat-att {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 16px;
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      background-color: #eff4ff;
    }
  }

  .has-unread-chat {
    background-color: #f2f6ff;
    border: 1px solid #20419b;
  }

  .red-dot-new {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }

  .loading-btn-icon {
    display: none;
  }

  .loading-btn .loading-btn-icon {
    display: block !important;
  }

  .loading-btn {
    pointer-events: none;
    color: white;
    border-color: #98a2b3 !important;
  }

  .loading-btn span {
    display: none;
  }

  .livestream-redot {
    background-color: #d92d20;
    color: white;
    padding: 1px 8px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
  }

  .video-player-box {
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: percentage(9/16);
    }

    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }

    @mixin transition($args...) {
      -webkit-transition: $args;
      -moz-transition: $args;
      -ms-transition: $args;
      -o-transition: $args;
      transition: $args;
    }

    // Sass for videojs-watermark
    .video-js {
      &.vjs-watermark {
        display: block;
      }

      .vjs-watermark-content {
        opacity: 0.99;
        position: absolute;
        padding: 16px 16px 0 0;
        width: 4rem;
        height: 4rem;
        @include transition(visibility 1s, opacity 1s);
      }

      // pre-defined positions
      .vjs-watermark-top-right {
        right: 0;
        top: 0;
      }

      .vjs-watermark-top-left {
        left: 0;
        top: 0;
      }

      // bottom set to 30px to account for controlbar
      .vjs-watermark-bottom-right {
        right: 0;
        bottom: 30px;
      }

      .vjs-watermark-bottom-left {
        left: 0;
        bottom: 30px;
      }

      //fade out when the user is not active and the video is playing.
      &.vjs-user-inactive.vjs-playing .vjs-watermark-fade {
        opacity: 0;
      }
    }
  }
}
</style>
