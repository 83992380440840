<template>
  <div class="bg-white h-full" id="ConferenceOrg">
    <div class="flex h-full">
      <div class="min-h-full border-right">
        <el-menu
          :default-active="indexActive"
          class="el-menu-vertical-demo border-0 sticky top-0"
          @select="handleSelected"
        >
          <el-menu-item :index="item.key" v-for="(item, index) in tabList" :key="index">
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="w-full h-full px-3 overflow-y-auto relative">
        <!-- <keep-alive>
        </keep-alive>-->
        <div class="pb-4">
          <component :is="currentComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Overview from '@/pages/Conference/Overview.vue'
import Reports from '@/pages/Conference/Reports.vue'
import Speakers from '@/pages/Conference/Speakers.vue'
import Guests from '@/pages/Conference/Guests.vue'

export default {
  name: 'ConferenceTreeView',
  components: {
    overview: Overview,
    reports: Reports,
    speakers: Speakers,
    guests: Guests
  },
  data () {
    const tabList = [
      {
        key: 'Overview',
        title: this.$t('Tổng quan')
      },
      {
        key: 'Reports',
        title: this.$t('Danh sách báo cáo')
      },
      {
        key: 'Speakers',
        title: this.$t('Báo cáo viên')
      },
      {
        key: 'Guests',
        title: this.$t('Khách mời')
      }
    ]
    return {
      tabList,
      currentRouteName: this.$route.query.tab || 'Overview',
      activeIndex: 0
    }
  },
  mounted () {
    this.handleSelected(this.currentRouteName)
  },
  computed: {
    indexActive () {
      const home = ['Master']
      const reports = ['Reports', '']
      const speakers = ['Speakers']
      const guests = ['Guests']

      if (this.isRouteNames(home)) return 'Overview'
      if (this.isRouteNames(reports)) return 'Reports'
      if (this.isRouteNames(speakers)) return 'Speakers'
      if (this.isRouteNames(guests)) return 'Guests'

      return 'Overview'
    },
    currentComponent () {
      return this.currentRouteName?.toLowerCase()
    }
  },
  watch: {
    '$route.query.tab' (value) {
      this.currentRouteName = value || 'Overview'
    }
  },
  methods: {
    isRouteNames (routeNames = []) {
      return routeNames.includes(this.currentRouteName)
    },
    handleSelected (key) {
      const rawData = this.tabList.map(item => item.key)
      const data = rawData.findIndex(item => item === key)

      this.activeIndex = data

      if (key !== this.$route.query.tab) {
        this.$router.replace({ query: { tab: key } }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 280px;
    // height: 100%;
  }
  .el-menu--collapse {
    width: 48px;
  }
  .el-menu-item,
  .el-submenu__title,
  .el-submenu .el-menu-item {
    height: fit-content;
    line-height: 32px;
    padding: 0 10px !important;
    white-space: pre-wrap;
  }

  .el-menu-item.is-active {
    background-color: #20409b !important;
  }
  .el-menu-item.is-active {
    color: white !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #e4e7ec;
  }
  .fliped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .el-menu-item-group__title {
    padding: 0;
    height: 0;
  }
  .cs-lh {
    line-height: 28px !important;
  }
}
</style>
